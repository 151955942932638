import './App.css';
import {
    Box,
    Divider,
    Drawer,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList, Table,
    ThemeProvider, Typography
} from "@mui/material";
import theme from "../theme";
import config from '../config';
import {Helmet} from "react-helmet";
import {Navigate, Route, Routes} from "react-router";
import PublisherCampaigns from "../components/Publisher/Campaign/PublisherCampaigns";
import {NavLink, useLocation, useSearchParams} from "react-router-dom";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {useEffect, useState} from "react";
import {styled} from "@mui/system";
import MuiAppBar from '@mui/material/AppBar';

import {
    Search as SearchIcon,
    SportsEsports,
    AltRoute,
    FormatListBulleted,
    OtherHouses,
    Rule,
    Group, Schema,
    AttachMoney
} from '@mui/icons-material';


import Search from "../components/Campaign/Search";
import CampaignType from "../components/AdminPanel/CampaignType/CampaignType";
import Header from "../components/Header";
import FormWrapper, {FORM_TYPES} from "../components/FormWrapper";
import Environment from "../components/AdminPanel/Environment/Environment";
import AudienceParameter from "../components/AdminPanel/AudienceParameter/AudienceParameter";
import UserGroup from "../components/AdminPanel/UserGroup/UserGroup";

import {useDispatch, useSelector} from "react-redux";
import {getEnvName, isNeedToShowMode, isStageAdmin} from "../components/Utils/CommonUtils";

import PublisherABTests from "../components/Publisher/Abtests/PublisherABTests";
import {configs, getDomainAlias} from "../constants";
import {Alert} from "@mui/lab";

import { MsalProvider, AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from '@azure/msal-react';
import {acquireToken} from "../core/api/authActions";

import logo from '../assets/wink.webm';
import Tournament from "../components/AdminPanel/Tournament/Tournament";
import PublisherCampaignsTemplates from "../components/Publisher/CampaignTemplates/PublisherCampaignsTemplates";
import LiveOpsVariants from "../components/LiveOpsVariants";
import Logs from "../components/AdminPanel/Logs";
import HistoryIcon from '@mui/icons-material/History';

import DataObjectIcon from '@mui/icons-material/DataObject';

const drawerWidth = 240;


const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));


export default function App({ instance }) {
    const [searchParams, setSearchParams] = useSearchParams("env");
    const [className, setClassName] = useState("")
    const dispatch = useDispatch();

    const sourceMode = useSelector((state) => state.data.SourceMode);
    const userSession = useSelector((state) => state.data.UserSession);

    const appMode = sourceMode.mode;
    const isShowMode = isNeedToShowMode();



    const env1 = getEnvName('env', sourceMode, searchParams)
    const env2 = getEnvName('env2', sourceMode, searchParams)
    const location = useLocation()

    const activeAccount = instance.getActiveAccount();

    const menu = [
        {title: 'Main', isDividerAfter: false, isInfoElement: true},
        {
            to: `/publisher/campaigns?env=${env1}&env2=${env2}`,
            path: '/publisher/campaigns',
            title: 'Campaigns',
            element: <PublisherCampaigns/>,
            icon: <SportsEsports size={"small"}/>,
            isDividerAfter: false,
            isInfoElement: false
        },
        {
            to: `/publisher/abtests?env=${env1}&env2=${env2}`,
            path: '/publisher/abtests',
            title: 'AB Tests',
            element: <PublisherABTests/>,
            icon: <AltRoute size={"small"}/>,
            isDividerAfter: false,
            isInfoElement: false,
        },
        {
            to: `/search?env=${env1}`,
            path: 'search',
            title: 'Search',
            element: <Search/>,
            icon: <SearchIcon size={"small"}/>,
            isDividerAfter: true,
            isInfoElement: false
        },

        {
            to: `/publisher/templates?env=${env1}&env2=${env2}`,
            path: '/publisher/templates',
            title: 'Templates',
            element: <PublisherCampaignsTemplates/>,
            icon: <Schema size={"small"}/>,
            isDividerAfter: true,
            isInfoElement: false
        },
        {
            to: `/variants?env=liveops`,
            path: '/variants',
            title: 'LiveOps Variants',
            element: <LiveOpsVariants />,
            disabled: !['cm','local','staging'].includes(getDomainAlias()),
            icon: <AttachMoney size={"small"}/>,
            isDividerAfter: true,
            isInfoElement: false
        },

        {title: 'Admin Panel', isDividerAfter: false, isInfoElement: true},
        {
            to: `/campaignType?env=${env1}`,
            path: 'campaignType',
            title: 'Campaign Types',
            element: <CampaignType/>,
            icon: <FormatListBulleted size={"small"}/>,
            isDividerAfter: false,
            isInfoElement: false
        },
        {
            to: `/environment?env=${env1}`,
            path: 'environment',
            title: 'Environments',
            element: <Environment/>,
            icon: <OtherHouses size={"small"}/>,
            isDividerAfter: false,
            isInfoElement: false
        },
        // {
        //     to: `/tournament?env=${env1}`,
        //     path: 'tournament',
        //     title: 'Tournament',
        //     element: <Tournament/>,
        //     icon: <SearchIcon size={"small"}/>,
        //     isDividerAfter: false,
        //     isInfoElement: false
        // },
        {
            to: `/parameter?env=${env1}`,
            path: 'parameter',
            title: 'Audience Requirements',
            element: <AudienceParameter/>,
            icon: <Rule size={"small"}/>,
            isDividerAfter: false,
            isInfoElement: false
        },
        {
            to: `/userGroup?env=${env1}`,
            path: 'userGroup',
            title: 'User Groups',
            element: <UserGroup/>,
            icon: <Group size={"small"}/>,
            isDividerAfter: false,
            isInfoElement: false
        },
        {
            to: `/logs?env=${env1}`,
            path: 'logs',
            title: 'Logs',
            element: <Logs />,
            icon: <HistoryIcon size={"small"}/>,
            isDividerAfter: false,
            isInfoElement: false
        }
        // {
        //     to: `/bundle?env=${env1}`,
        //     path: 'bundle',
        //     title: 'Bundles',
        //     element: <Bundle/>,
        //     icon: <SearchIcon size={"small"}/>,
        //     isDividerAfter: false,
        //     isInfoElement: false
        // },
        //{title: 'Admin', isDividerAfter: false, isInfoElement: true},
        //{to: '/settings', path: 'settings', title: 'Settings', element: <CampaignType/>, icon: <SettingsIcon size={"small"}/>, isDividerAfter: false, isInfoElement: false},
        //{to: '/userAccess', path: 'userAccess', title: 'User Access', element: <CampaignType/>, icon: <LockOpenIcon size={"small"}/>, isDividerAfter: false, isInfoElement: false},
    ];


    useEffect(() => {
        acquireToken(dispatch, instance);
    }, [dispatch, userSession.fetching]);

    useEffect(() => {
        setClassName(env2)
    }, [searchParams, location])

    useEffect(() => {
        const setCurrentPageTitle = () => {
            const currentItem = menu.find(item => location.pathname.includes(item.path));
            if (currentItem && currentItem.title) {
                document.title = currentItem.title + ' | Mephisto ' + configs[getDomainAlias()].title;
            } else {
                document.title = 'Mephisto ' + configs[getDomainAlias()].title;
            }
        };

        setCurrentPageTitle();

    }, [location])


    const [state, setState] = useState({
        open: false,
        authenticated: false
    });


    function getContent() {
        let Content
        Content =
            <Routes>
                {menu.map((object, index) => (
                    !object.isInfoElement && <Route key={index} path={object.path} element={object.element}/>
                ))}
                <Route path="/" element={<PublisherCampaigns />} />
              {/*  <Route path='/' element={<Publisher/>}/>*/}
                <Route path='/creator/abtests/:id?' element={<FormWrapper formType={FORM_TYPES.AB_TESTS}/>}/>
                <Route path='/creator/abtestsmini/:id?' element={<FormWrapper formType={FORM_TYPES.AB_TEST_MINI}/>}/>
                <Route path='/creator/templates/:id?' element={<FormWrapper formType={FORM_TYPES.TEMPLATES}/>}/>
                <Route path='/creator/campaigns/:id?' element={<FormWrapper formType={FORM_TYPES.CAMPAIGNS}/>}/>

            </Routes>;

        return Content

    }

    const handleDrawerOpen = () => {
        setState({
            ...state,
            open: true
        });
    };

    const handleDrawerClose = () => {
        setState({
            ...state,
            open: false
        });
    };

    const handleMenuItemClick = () => {
        setState({
            ...state,
            open: false
        });
    }

    const handleLoginPopup = () => {
        /**
         * When using popup and silent APIs, we recommend setting the redirectUri to a blank page or a page
         * that does not implement MSAL. Keep in mind that all redirect routes must be registered with the application
         * For more information, please follow this link: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations
         */
        instance
            .loginPopup({
                ...{},
                redirectUri: '/dummy.html',
            })
            // .then((data) => {
            //     console.log("AUTH DONE")
            //     console.log(data)
            //     dispatch({
            //         type: 'AUTH_SELF_SUCCESS', payload: {
            //             data: data.account.idTokenClaims.roles
            //         }
            //     });
            //     localStorage.setItem("azureToken", data.idToken)
            // })
            .catch((error) => console.log(error));
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (state.open && !document.querySelector('.MuiDrawer-root').contains(event.target) &&
                !document.querySelector('.menu-title').contains(event.target)) {
                setState({
                    ...state,
                    open: false
                });
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [state.open]);

    return (
        <MsalProvider instance={instance}>

            <ThemeProvider theme={theme}>

                <Helmet {...config.app}></Helmet>
                <Header open={state.open}
                        handleDrawerOpen={handleDrawerOpen}
                />

                <Box className={"wrapper" + " " + className}>
                    <Drawer
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                width: drawerWidth,
                                boxSizing: 'border-box',
                            },
                        }}
                        variant="persistent"
                        anchor="left"
                        open={state.open}
                    >
                        <DrawerHeader>
                            <Typography className={"menu-title"} variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                Mephisto&nbsp;
                                <small style={{ color: '#e20000' }}>
                                    {configs[getDomainAlias()].title}
                                </small>
                            </Typography>
                            <IconButton
                                onClick={handleDrawerClose}>
                                <ChevronLeftIcon/>
                            </IconButton>
                        </DrawerHeader>
                        <Divider/>
                        <MenuList>
                            {menu.filter(object => object.disabled!=true).map((object, index) => (
                                !object.isInfoElement ?
                                    <Box key={index}>
                                        <MenuItem
                                            component={NavLink}
                                            to={object.to}
                                            onClick={handleMenuItemClick}
                                            disabled={object.disabled}
                                            key={object.to}
                                        >
                                            <ListItemIcon>
                                                {object.icon}
                                            </ListItemIcon>
                                            <ListItemText>{object.title}</ListItemText>

                                        </MenuItem>
                                        {object.isDividerAfter &&
                                            <Divider/>
                                        }
                                    </Box> :
                                    <MenuItem
                                        component={Box}
                                        disabled={true}
                                        className={"pending"}
                                        key={object.title}
                                    >
                                        <ListItemText>{object.title}</ListItemText>

                                    </MenuItem>

                            ))}

                            <Box >

                                <Divider sx={{pt:1, mb:1}}/>


                                <MenuItem
                                component="a"
                                href={configs[getDomainAlias()].baseUrl+"/swagger-ui/index.html"}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <ListItemIcon><DataObjectIcon /></ListItemIcon>
                                <ListItemText>Swagger UI</ListItemText>
                            </MenuItem>
                            </Box>
                        </MenuList>
                    </Drawer>

                    <Main open={state.open} className="main-menu-wrap">

                    </Main>

                    <Box
                        sx={{flexGrow: 1, pt:3, pl:2, pr:2, pb:2}}
                    >
                        <AuthenticatedTemplate>

                            {isStageAdmin(userSession) ? getContent() : <Alert severity={"info"}>You are not allowed Stage Mephisto.</Alert> }

                        </AuthenticatedTemplate>

                        <UnauthenticatedTemplate>
                            <Alert severity={"info"}>Sign-in to get access to Mephisto {configs[getDomainAlias()].title}
                            </Alert>



                        </UnauthenticatedTemplate>

                    </Box>
                </Box>


            </ThemeProvider>
        </MsalProvider>
    )
}
