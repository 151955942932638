import React from "react";
import AudienceInclude from "./AudienceInclude";
import AudienceExclude from "./AudienceExclude";
import AudienceRange from "./AudienceRange";
import AudienceVersion from "./AudienceVersion";
import AudienceOffer from "./AudienceOffer";

export default function Audience({audience, children}) {
    const getOffers = () => {
        const profileOffset = audience.profileOffset || [];
        const offerInProfile = audience.offerInProfile || [];
        const offerNotInProfile = audience.offerNotInProfile || [];

        const offerInactiveRule = audience.offerInactiveRule || [];
        const offerTypeInactiveRule = audience.offerTypeInactiveRule || [];

        const offerTagInactiveRule = audience.offerTagInactiveRule || [];

        return [...profileOffset,
            ...offerInProfile,
            ...offerNotInProfile,
            ...offerInactiveRule,
            ...offerTypeInactiveRule,
            ...offerTagInactiveRule
        ];
    };

    return (
        <div className="audience-item">
            <div className="audience-item__inline">{children}</div>
            {audience.numberRange!= undefined && audience.numberRange.length > 0 && <div className="audience-item__inline">
                {audience.numberRange.map((item, index) =>
                    <AudienceRange key={index} audience={item}></AudienceRange>
                )}
            </div>}
            {audience.versionRange!= undefined && audience.versionRange.length > 0 && <div className="audience-item__inline">
                {audience.versionRange.map((item, index) =>
                    <AudienceVersion key={index} audience={item}></AudienceVersion>
                )}
            </div>}
            {audience.exclude!= undefined && audience.exclude.length > 0 && <div className="audience-item__inline">
                {audience.exclude.map((item, index) =>
                    <AudienceInclude title={"exclude"} audience={item}></AudienceInclude>
                )}
            </div>}
            {audience.excludeAll!= undefined && audience.excludeAll.length > 0 && <div className="audience-item__inline">
                {audience.excludeAll.map((item, index) =>
                    <AudienceInclude title={"exclude all"} audience={item}></AudienceInclude>
                )}
            </div>}
            {audience.excludeAny!= undefined && audience.excludeAny.length > 0 && <div className="audience-item__inline">
                {audience.excludeAny.map((item, index) =>
                    <AudienceInclude title={"exclude any"} audience={item}></AudienceInclude>
                )}
            </div>}
            {audience.include!= undefined && audience.include.length > 0 && <div className="audience-item__inline">
                {audience.include.map((item) =>
                    <AudienceInclude title={"include"} audience={item}></AudienceInclude>
                )}
            </div>}
            {audience.includeAll!= undefined && audience.includeAll.length > 0 && <div className="audience-item__inline">
                {audience.includeAll.map((item) =>
                    <AudienceInclude title={"include all"} audience={item}></AudienceInclude>
                )}
            </div>}
            {audience.includeAny!= undefined && audience.includeAny.length > 0 && <div className="audience-item__inline">
                {audience.includeAny.map((item) =>
                    <AudienceInclude title={"include any"} audience={item}></AudienceInclude>
                )}
            </div>}
            {getOffers().length > 0 && <div className="audience-item__inline">
                {getOffers().map((item) =>
                    <AudienceOffer audience={item}></AudienceOffer>
                )}
            </div>}
        </div>
    )

}