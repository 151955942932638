import React, { memo, useMemo, useCallback } from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';

const StatusSelector = memo(({
                                 status,
                                 statusList,
                                 onChange,
                                 onClick
                             }) => {
    const menuItems = useMemo(() =>
            statusList.map((item) => (
                <MenuItem
                    key={item}
                    className={item}
                    value={item}
                >
                    {item}
                </MenuItem>
            )),
        [statusList]
    );

    const handleChange = useCallback((event) => {
        onChange(event);
    }, [onChange]);

    const handleClick = useCallback((e) => {
        e.stopPropagation();
        onClick?.(e);
    }, [onClick]);

    return (
        <FormControl sx={{ width: 90, margin: 0 }} size="small">
            <Select
                displayEmpty
                renderValue={(selected) => selected || 'Status'}
                className={status}
                value={status}
                onChange={handleChange}
                onClick={handleClick}
            >
                {menuItems}
            </Select>
        </FormControl>
    );
}, (prevProps, nextProps) => {
    return (
        prevProps.status === nextProps.status &&
        prevProps.statusList.length === nextProps.statusList.length
    );
});

export default StatusSelector;