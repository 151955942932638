import {
    Box,
    Button,
    Card,
    CardContent, Chip, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    IconButton,
    Menu,
    MenuItem, Tooltip, Typography
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import QrCodeIcon from "@mui/icons-material/QrCode";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ShareIcon from "@mui/icons-material/Share";
import BuildIcon from "@mui/icons-material/Build";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import BarChartIcon from "@mui/icons-material/BarChart";
import DeleteIcon from "@mui/icons-material/Delete";
import {QRCodeSVG} from "qrcode.react";
import {useContext, useState, Fragment} from "react";
import {useBuildBundleMutation, useBuildQrMutation, useDeleteVariantBaseMutation} from "../../core/reducers/variant";
import {NewVariantTestModal} from "./NewVariantTestModal";
import clipboardCopy from "clipboard-copy";
import {SnackBarContext} from "../../core/context/snackBarProvider";
import {isEmpty, LIVEOPS_ENV} from "../Utils/CommonUtils";
import {Link} from "react-router-dom";
import {CloneFromCampaign} from "../CloneFromCampaign";
import {FORM_TYPES} from "../FormWrapper";
import Loading from "../Loading";
import {Alert} from "@mui/lab";

export const VariantItem = ({item, variant, parent, env}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openQR, setOpenQR] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openBuild, setOpenBuild] = useState(false);
    const [openCreateCampaign, setOpenCreateCampaign] = useState(false);

    const [deleteVariant, deleteVariantResponse] = useDeleteVariantBaseMutation();
    const [buildBundle, buildBundleResponse] = useBuildBundleMutation();
    const [buildQr, buildQrResponse] = useBuildQrMutation();


    const useSnackBars = () => useContext(SnackBarContext);
    const {addAlert} = useSnackBars();

    const realId = item.hasOwnProperty('id')?item.id:item.bundleId

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteOpen = () => {
        setOpenDelete(true);
        handleMenuClose();
    };

    const handleDeleteClose = () => {
        setOpenDelete(false);
    };
    const handleDelete = () => {
        debugger
        deleteVariant({env: env,variant: variant, id: realId})
        setOpenDelete(false);
    };


    const handleQROpen = () => {
        setOpenQR(true);
    };

    const handleQRClose = () => {
        setOpenQR(false);
    };


    const handleCopyClick = (text) => {
        clipboardCopy(text);

        addAlert({text: 'copied', type: 'success'})

        return false;
    };

    const [openCreate, setOpenCreate] = useState(false);
    const [formMode, setFormMode] = useState('');

    const qr = "clockmaker-dev://?label=" + encodeURI(`disableSynchro=true&preloadBundles=true&variantBundle=${item?.bundleId}&variantBalance=${item.balanceLink}`)

    const link = document.location.origin + document.location.pathname + '?env='+ LIVEOPS_ENV + '&filterType=' + item?.type + '&filterText=' + item?.id


    const getAnalyticsLink = () => {
        if(variant != 'test') return '';
        if(!item.hasOwnProperty('validations')) return '';

        if(item?.validations==null || isEmpty(item?.validations?.campaignId)) return ''

        const ab_test_name = item?.validations?.campaignId.replace(/^(\d{4}(?:_\d{2}_\d{2}|\d{4}))/, '$1_AB');

        const analytics_link = 'https://tableau.belka-games.com/#/views/ABver2/CMAB?Parameters.test_id_url='+ab_test_name+'&TEST=';

        return analytics_link
    }

    const new_campaign_link = document.location.origin + "/creator/campaigns?env="+env+"&mode=variants&type="+item?.type+"&bundleId="+item?.id
    const new_ab_link = document.location.origin + "/creator/abtestsmini?env="+env+"&mode=variants&type="+item?.type+"&bundleId="+item?.id

    const statusColors = {
        'READY':'warning',
        'CREATING':'error',
        'BUILT':'success',
    }

    const disabledStatus = 'creating'

    function handleClickNewCampaign(e) {
        e.stopPropagation()
        return isEmpty(item?.validations.campaignId)
    }

    const disabledNewCampaign = isEmpty(item?.validations?.campaignId)

    const handleBuildQr = () => {
        debugger
        if(item.qrBuild==null)
            buildQr({id:item.id,env:env})

        handleQROpen()
    }
    const handleBuild = () => {
        debugger

        if(item.bundleBuild==null)
            buildBundle({id:item.id,env:env})

        setOpenBuild(true)
    }

    return <>
        <Card className="campaign-item" style={{marginBottom: '3px'}}>
            <CardContent>


                <div className="campaign-item__body" >
                    <div >
                        <div className="campaign-item__header">

                            <div className="campaign-item__name">

                                <div >


                                    {variant=='base'&&<span >{item.bundleId}</span>}
                                    {variant=='test'&&<span >{item.id}</span>}

                                </div>

                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {variant=='base'&&<Button variant="outlined" onClick={() => setOpenCreate(true)}>New test</Button>}
                        {variant=='test'&&<Chip variant={'outlined'} label={item.status}  color={statusColors.hasOwnProperty(item.status)?statusColors[item.status]:'primary'}/>}

                        <Tooltip arrow title={"Edit Balance"}>
                            <IconButton disabled={item.balanceLink==null||item.status?.toLowerCase()==disabledStatus} href={item.balanceLink} target={"_blank"}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        {variant=='test'&&<IconButton disabled={item.status?.toLowerCase()==disabledStatus} onClick={handleBuildQr}>
                            <QrCodeIcon />
                        </IconButton>}

                        <Tooltip arrow title={"Knowledge Base"}>
                            <IconButton disabled={item.status?.toLowerCase()==disabledStatus} href={item.docLink} target={"_blank"}>
                                <MenuBookIcon />
                            </IconButton>
                        </Tooltip>

                        <IconButton disabled={item.status?.toLowerCase()==disabledStatus} onClick={handleMenuClick}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            {variant=='test'&&<Box>
                                <MenuItem onClick={() => {handleCopyClick(link)}}>
                                    <ShareIcon style={{ marginRight: '8px' }} /> Share
                                </MenuItem>
                                <MenuItem onClick={handleBuild} >
                                    <BuildIcon style={{ marginRight: '8px' }} /> Build
                                    {item.hasOwnProperty('bundleBuild') && item.bundleBuild != null && <> <small>({item.bundleBuild.status.toLowerCase()})</small></>}
                                </MenuItem>

                                <Tooltip arrow placement={"left"} title={!item?.validations?.bundleExists?"No bundle":""}>
                                    <MenuItem
                                              onClick={(e) => {
                                                  if(!item?.validations?.bundleExists) {
                                                      e.preventDefault();
                                                      e.stopPropagation();
                                                      setOpenCreateCampaign(false)

                                                  } else {
                                                      setOpenCreateCampaign(true)
                                                  }
                                              }}
                                              className={!item?.validations?.bundleExists?"disabledMenu":""}
                                              >
                                            <AddCircleOutlineIcon style={{ marginRight: '8px' }} /> New Campaign
                                    </MenuItem>
                                </Tooltip>

                                <Tooltip arrow placement={"left"}  title={disabledNewCampaign?"No campaign":""}>
                                    <MenuItem
                                        component={Link} to={new_ab_link}
                                        className={disabledNewCampaign?"disabledMenu":""}

                                        onClick={(e) => {
                                            if(disabledNewCampaign) {
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }
                                        }}
                                    >
                                        <AddCircleOutlineIcon style={{ marginRight: '8px' }} /> New AB Test
                                    </MenuItem>
                                </Tooltip>

                                <Tooltip arrow placement={"left"}  title={(getAnalyticsLink()==''||disabledNewCampaign)?"No campaign/no bundle":""}>
                                    <MenuItem
                                        component={"a"} href={getAnalyticsLink()} target={"_blank"}
                                        className={disabledNewCampaign|| !item?.validations.bundleExists?"disabledMenu":""}

                                        onClick={(e) => {
                                            if(disabledNewCampaign || !item?.validations.bundleExists) {
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }
                                        }}
                                    >
                                        <BarChartIcon style={{ marginRight: '8px' }} /> Analytics
                                    </MenuItem>
                                </Tooltip>
                            </Box>}
                            <MenuItem onClick={handleDeleteOpen}>
                                <DeleteIcon style={{ marginRight: '8px' }} /> Delete
                            </MenuItem>
                        </Menu>
                    </div>
                </div>

            </CardContent>
        </Card>

        {/* Campaign Popup */}
        {openCreateCampaign&&<Dialog open={openCreateCampaign} onClose={() => {setOpenCreateCampaign(false);setFormMode('')}}>
            <DialogTitle>Create campaign with<br /><strong>{item.id}</strong></DialogTitle>
            <DialogContent>
                {formMode==''&&<Box display={'flex'} justifyContent={'center'}>
                    <Box component="main" maxWidth="sm" sx={{mb: 2, mt:0}} display={'grid'}>
                        <div>
                            <Button component={Link} to={new_campaign_link} variant={"outlined"} fullWidth={true} sx={{mb:1}} onClick={() => setFormMode('FULL')}>Create from scratch</Button>
                        </div>
                        <div>
                            <Button variant={"outlined"} onClick={() => setFormMode('CLONE')}>Copy settings from existing campaign</Button>
                        </div>
                    </Box>
                </Box>}

                {formMode == 'CLONE' && <CloneFromCampaign env={env} bundleId={item.id} type={item.type} />}

            </DialogContent>
            <DialogActions>
                <Button onClick={() => {setOpenCreateCampaign(false);setFormMode('')} } variant={'contained'}>Close</Button>
            </DialogActions>
        </Dialog>}

        {/* QR Code Popup */}
        {openQR&&<Dialog open={openQR} onClose={handleQRClose}>
            <DialogTitle>QR Build {item.id}</DialogTitle>
            <DialogContent>
                <DialogContentText>

                {<Alert sx={{mb:1}} severity={"warning"}>Через 3 минуты к вам в Slack придет QR для отсмотра</Alert>}
                {/*<QRCodeSVG size={256} leve={'M'} value={qr} />*/}
                {item.qrBuild!=null && <>
                    <div>Status: <b>{item.qrBuild.status}</b></div>
                    <div>ID: <b>{item.qrBuild.id}</b></div>
                    {item.qrBuild.url!=null&&<div>Build URL<br />
                        <a href={item.qrBuild.url} target={"_blank"}>{item.qrBuild.url}</a></div>}
                    {item.qrBuild.errorMessage!=null&&<div>Error: {item.qrBuild.errorMessage}</div>}
                </>}

                {item.qrBuild==null &&
                    <Loading isLoading={buildQrResponse.isLoading} isError={buildQrResponse.isError} error={buildQrResponse.error}>
                        <div>Status: <b>{buildQrResponse?.data?.status}</b></div>
                        <div>ID: <b>{buildQrResponse?.data?.id}</b></div>
                        {buildQrResponse?.data?.url!=null&&<div>Build URL<br />
                            <a href={buildQrResponse?.data?.url} target={"_blank"}>{buildQrResponse.data.url}</a>
                        </div>}
                        {buildQrResponse?.data?.errorMessage!=null&&<div>Error: {buildQrResponse?.data?.errorMessage}</div>}
                    </Loading>}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleQRClose} variant={'contained'}>Close</Button>
            </DialogActions>
        </Dialog>}

        {/* Delete Confirmation Popup */}
        {openDelete&&<Dialog open={openDelete} onClose={handleDeleteClose}>
            <DialogTitle>Delete</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {(parent!=undefined&&parent.length>0) ? <>There are connected Test Variants for this bundle</> : <>Are you sure you want to delete <b>{realId}</b>?</> }

                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDeleteClose} variant={'outlined'}>Cancel</Button>
                {!((parent!=undefined&&parent.length>0))&&<Button onClick={handleDelete} color="primary" variant={'contained'}>Yes</Button>}
            </DialogActions>
        </Dialog>}

        {/* Build Popup */}
        {openBuild&&<Dialog open={openBuild} onClose={() => {setOpenBuild(false)}}>
            <DialogTitle>
                Build info {item.bundleBuild==null?<CircularProgress size={20} color="inherit"/>:""}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {item.bundleBuild!=null && <>
                        <div>Status: <b>{item.bundleBuild.status}</b></div>
                        <div>ID: <b>{item.bundleBuild.id}</b></div>
                        {item.bundleBuild.url!=null&&<div>Build URL<br />
                            <a href={item.bundleBuild.url} target={"_blank"}>{item.bundleBuild.url}</a></div>}
                        {item.bundleBuild.errorMessage!=null&&<div>Error: {item.bundleBuild.errorMessage}</div>}
                    </>}

                    {item.bundleBuild==null &&
                        <Loading isLoading={buildBundleResponse.isLoading} isError={buildBundleResponse.isError} error={buildBundleResponse.error}>
                        <div>Status: <b>{buildBundleResponse?.data?.status}</b></div>
                        <div>ID: <b>{buildBundleResponse?.data?.id}</b></div>
                        {buildBundleResponse?.data?.url!=null&&<div>Build URL<br />
                            <a href={buildBundleResponse?.data?.url} target={"_blank"}>{buildBundleResponse.data.url}</a>
                        </div>}
                        {buildBundleResponse?.data?.errorMessage!=null&&<div>Error: {buildBundleResponse?.data?.errorMessage}</div>}
                    </Loading>}

                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>setOpenBuild(false)} variant={'outlined'}>Close</Button>
            </DialogActions>
        </Dialog>}

        {openCreate&&<NewVariantTestModal openCreate={openCreate} setOpenCreate={setOpenCreate} base={item} env={env} />}

    </>
}