import React, {useEffect, useState, useRef} from 'react';
import Loading from "../Loading";
import {
    Autocomplete,
    Box,
    Button, Card, CardContent, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    FormControl, FormControlLabel, FormHelperText, Grid, IconButton,
    InputAdornment,
    InputLabel, Link, ListSubheader, Menu,
    MenuItem,
    Select, Table, TableBody, TableCell, TableHead, TableRow,
    TextField,
    Tooltip, Typography
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {getEnvName, getFromStorage, isEmpty, LIVEOPS_ENV} from "../Utils/CommonUtils";
import CampaignFrame from "../Publisher/Campaign/CampaignFrame";
import FmdBadIcon from "@mui/icons-material/FmdBad";
import CopyIcon from "@mui/icons-material/ContentCopy";
import Audience from "../Publisher/Audience";

import EditIcon from '@mui/icons-material/Edit';
import QrCodeIcon from '@mui/icons-material/QrCode';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShareIcon from '@mui/icons-material/Share';
import BuildIcon from '@mui/icons-material/Build';
import DeleteIcon from '@mui/icons-material/Delete';
import {QRCodeSVG} from 'qrcode.react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BarChartIcon from '@mui/icons-material/BarChart';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from "@mui/icons-material/Close";
import {LoadingButton} from "@mui/lab";
import {NewVariantModal} from "./NewVariantModal";

import {useVariantBaseQuery} from "../../core/reducers/variant";
import {VariantItem} from "./VariantItem";
import {NewVariantTestModal} from "./NewVariantTestModal";
import {useLocation, useSearchParams} from "react-router-dom";
import {useHistory} from "react-router";
import {BaseVariants} from "./BaseVariants";
import {TestVariants} from "./TestVariants";
import {useDispatch, useSelector} from "react-redux";

export default function LiveOpsVariants() {

    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [openCreate, setOpenCreate] = useState(false);

    const sourceMode = useSelector((state) => state.data.SourceMode);
    const [env1, setEnv1] = useState(getEnvName('env', sourceMode, searchParams))

    const [filterText, setFilterText] = useState(searchParams.get('filterText') || '');
    const [filterType, setFilterType] = useState(searchParams.get('filterType') || 'universal_offer');

    const handleFilterChange = (event) => {
        const newFilterText = event.target.value;
        setFilterText(newFilterText);

        setSearchParams({
            'env': LIVEOPS_ENV,
            'filterType': filterType,
            'filterText': newFilterText
        })
    }


    const handeFilterType = (event) => {
        const newFilterType = event.target.value;
        setFilterType(newFilterType);
        setSearchParams({
            'env': LIVEOPS_ENV,
            'filterText': filterText,
            'filterType': newFilterType,
        })
    }


    return (
        <Box>
            <Box
                className={"filters-sticky"}
                sx={{ display: 'flex' }}
            >

                <Box sx={{ pr:1, width:"200px" }}>
                    <FormControl fullWidth>
                        <InputLabel id="super-type-id">Type</InputLabel>
                        <Select
                            labelId="super-type"
                            id="super-type-select"
                            value={filterType}
                            label="Super Type"
                            onChange={handeFilterType}
                        >
                            <MenuItem value="All types">All</MenuItem>
                            <MenuItem value="universal_offer">UNI</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ flexGrow: 1, pr:1,  }}>
                    <TextField
                        sx={{width: '100%'}}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon/>
                                </InputAdornment>
                            ),
                        }}
                        value={filterText}
                        placeholder="campaign name"
                        onChange={(event) => handleFilterChange(event)}></TextField>
                </Box>

            </Box>


            <Grid container>
                <Grid item sm={12} md={6} sx={{pr:1}}>

                    <BaseVariants env={env1} setOpenCreate={setOpenCreate} filterText={filterText} filterType={filterType} />
                </Grid>
                <Grid item sm={12} md={6} sx={{pr:1}}>

                    <TestVariants env={env1} filterText={filterText} filterType={filterType} />
                </Grid>


            </Grid>
            {openCreate&&<NewVariantModal openCreate={openCreate} setOpenCreate={setOpenCreate} env={env1} />}

        </Box>
    );
}