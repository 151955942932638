import {Fragment} from "react";
import {Box, Divider, Grid, List, ListItemButton, Typography} from "@mui/material";
import dayjs from "dayjs";
import {getAudienceSection, getListItem} from "../../CommonSummary";

export default function SummaryStep(props) {

    const timezone = require('dayjs/plugin/timezone');
    const utc = require('dayjs/plugin/utc');
    dayjs.extend(timezone);
    dayjs.extend(utc);

    return (
        <Fragment>
            <Grid container minWidth={875}>
                <Grid xs={12} item>
                    <Typography component="h1" variant="h5">
                        Basic
                    </Typography>
                    <Divider/>
                </Grid>
                <Grid xs={12} item container>
                    <List id='list' style={{width: '100%'}}>
                        {getListItem("ID:", props.currentCampaign.campaignId)}
                        {getListItem("Group:", props.currentCampaign.groupId)}
                        {getListItem("Priority:", props.currentCampaign.priority)}
                        {getListItem("Type:", props.currentCampaign.offerType)}
                        {getListItem("Bundle:", props.currentCampaign.bundleId)}
                        {getListItem("Tag:", props.currentCampaign.tag)}
                    </List>
                </Grid>
                <Grid xs={12} item>
                    <Typography component="h1" variant="h5">
                        Distribution
                    </Typography>
                    <Divider/>
                </Grid>

                <Grid xs={12} item container>
                    <List id='list' style={{width: '100%'}}>
                        {getListItem("Type:", props.currentCampaign.distributionType)}
                        {getListItem("Hours:", props.currentCampaign.durationHour !== "" ? props.currentCampaign.durationHour : "-")}
                        {getListItem("Announce From:",
                            props.currentCampaign.announceDurationTime.utcOffset(180).format('DD.MM.YYYY HH:mm')
                        )}
                        {getListItem("From:",
                            props.currentCampaign.from.utcOffset(180).format('DD.MM.YYYY HH:mm')
                        )}
                        {getListItem("Till:",
                            props.currentCampaign.till.utcOffset(180).format('DD.MM.YYYY HH:mm')
                        )}
                        {getListItem("Extra Time Till:",
                            props.currentCampaign.extraDurationTime.utcOffset(180).format('DD.MM.YYYY HH:mm')
                        )}
                    </List>
                </Grid>
                <Grid xs={12} item>
                    <Typography component="h1" variant="h5">
                        Audiences
                    </Typography>
                    <Divider/>
                </Grid>

                {props.currentCampaign.audiences.map(audience => {
                        return getAudienceSection(audience, props.currentCampaign)
                    }
                )}


                {props.isTemplate&&<>
                    <Grid xs={12} item>
                        <Typography component="h1" variant="h5">
                            Template
                        </Typography>
                        <Divider/>
                    </Grid>

                    <Grid xs={12} item container>
                        <List id='list' style={{width: '100%'}}>
                            {getListItem("Delay Days:", props.currentCampaign.schedule.delayDays)}
                            {getListItem("Till:", props.currentCampaign.schedule.till!=null?props.currentCampaign.schedule.till.utcOffset(180).format('DD.MM.YYYY HH:mm'):null)}
                        </List>
                    </Grid>
                </>}
            </Grid>
        </Fragment>
    )
}