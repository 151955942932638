import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {
    Button,
    Card,
    CardContent,
    Checkbox, Chip, ClickAwayListener,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl, InputLabel,
    Link, List, ListItem, ListItemText,
    MenuItem,
    Select,
    Tooltip, Typography
} from "@mui/material";
import { tooltipClasses } from '@mui/material/Tooltip';

import CampaignDetailsDialog from "../../Dialogs/CampaignDetailsDialog";
import HistoryDialog from "../../Dialogs/HistoryDialog";
import CampaignJsonDialog from "../../Dialogs/CampaignJsonDialog";
import Audience from "../Audience";
import {useSetStatusMutation} from "../../../core/reducers/campaign";
import CampaignBundleDialog from "../../Dialogs/CampaignBundleDialog";
import EditIcon from '@mui/icons-material/Edit';
import CopyIcon from '@mui/icons-material/ContentCopy';
import {LoadingButton} from "@mui/lab";
import {CampaignItemContext} from "../../../core/context/context";
import dayjs from "dayjs";

import clipboardCopy from 'clipboard-copy';
import {SnackBarContext} from "../../../core/context/snackBarProvider";
import {useNavigate} from "react-router";
import {useSelector} from "react-redux";
import {isProdAdmin} from "../../Utils/CommonUtils";
import CampaignBundleRollbackDialog from "../../Dialogs/CampaignBundleRollbackDialog";
import FmdBadIcon from '@mui/icons-material/FmdBad';
import {useSetTemplateStatusMutation} from "../../../core/reducers/templates";
import { styled } from '@mui/material/styles';
import StatusSelector from "../StatusSelector";


const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        boxShadow: '3px 1px 6px 4px rgba(0,0,0,0.2)',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '0px solid #dadde9',
    },
}));

const CampaignItem = ({item, bundle, rightPanel, showRainbow}) => {
    const {campaigns, types, type, items, bundles, onSelectItem, selectedList, env, createDisabled, isTemplate} = useContext(CampaignItemContext)

    const campaignType = useMemo(() => { return campaigns.types.filter(_ct => _ct.readableType == item.type)[0] }, [item.type])
    //const campaignType = types[item.type]
    const { addAlert } = useContext(SnackBarContext);

    const [open, setOpen] = useState(false);
    const [openTooltip, setOpenTooltip] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [bundlesOpen, setBundlesOpen] = useState(false);
    const [jsonOpen, setJsonOpen] = useState(false);
    const [historyOpen, setHistoryOpen] = useState(false);

    const [showConfirmStatusChange, setShowConfirmStatusChange] = useState(false)
    const [status, setStatus] = useState('')
    const [prevStatus, setPrevStatus] = useState('')

    const statusList = ["ACTIVE", "SUSPENDED", "HIDDEN", "DEACTIVATED"]

    const [selected, setSelected] = useState(selectedList != undefined ? selectedList.indexOf(item.id) >= 0 : false)

    const [timeLeftTill, setTimeLeftTill] = useState()
    const [timeLeftFrom, setTimeLeftFrom] = useState()
    const [timeLeftFromMinus, setTimeLeftFromMinus] = useState()
    const [timeLeftTillPlus, setTimeLeftTillPlus] = useState();

    const [bundleHistoryDialog, setBundleHistoryDialog] = useState({
        isOpen:false,
        generation: '',
        bundle: item.bundleId
    })

    const sourceMode = useSelector((state) => state.data.SourceMode);
    const appMode = sourceMode.mode;

    const navigate = useNavigate();



    const userSession = useSelector((state) => state.data.UserSession);

    const timeClassName = useMemo(() => {
        if (!showRainbow) return '';
        if (campaignType == null) return '';

        let current_time = new Date().getTime() / 1000;

        if (current_time < (item.distribution.from - campaignType.announceDuration)) return 'aqua';
        if (
            current_time >= (item.distribution.from - campaignType.announceDuration) &&
            current_time < item.distribution.from
        ) return 'lightgreen';
        if (
            current_time >= item.distribution.from &&
            current_time < item.distribution.till
        ) return 'green';
        if (
            current_time >= item.distribution.till &&
            current_time < (item.distribution.till + campaignType.extraDuration)
        ) return 'yellow';
        if (current_time >= (item.distribution.till + campaignType.extraDuration)) return 'red';

        return '';
    }, [showRainbow, campaignType, item.distribution]);

    useEffect(() => {
        setStatus(item.status);
        setPrevStatus(item.status);
    }, [item.status, item.id]);

    const handleCopyClick = (text) => {
        clipboardCopy(text);

        addAlert({text: 'copied', type: 'success'})

    };

    const handleSelect = useCallback((event) => {
        onSelectItem({item: item, bundle: getBundleInfo()})
        setSelected(!selected)
    },[onSelectItem, bundle])

    const [
        saveStatus,
        saveStatusResponse
    ] = useSetStatusMutation()

    const [
        saveTemplateStatus,
        saveTemplateStatusResponse
    ] = useSetTemplateStatusMutation()

    const responseObj = isTemplate ? saveTemplateStatusResponse : saveStatusResponse

    const confirmSaveStatus = async () => {
        debugger
        let data = {
            environment: env,
            id: item.id,
            status: status
        }

        let response = {};
        if(isTemplate) {
            response = await saveTemplateStatus(data)
        } else {
            response = await saveStatus(data)
        }

        console.log(response)
        debugger
        if (response.hasOwnProperty('data') && (
            ( !isTemplate&&response.data?.status?.toLowerCase() == status.toLowerCase() ) ||
            ( isTemplate&&response.data.hasOwnProperty('id') )
        ))
            addAlert({text: 'Status changed', type: 'success'})
        else
            addAlert({text: 'Error ' + response?.error?.data?.message, type: 'error'})

        setShowConfirmStatusChange(false)
        setPrevStatus(status)
    }
    const cancelSaveStatus = () => {
        setStatus(prevStatus)
        setShowConfirmStatusChange(false)
    }
    const handleChangeStatus = (event) => {
        if(!isProdAdmin(env,userSession)) return false;

        setStatus(event.target.value)
        setShowConfirmStatusChange(true)
    }


    const formatdate = (timestamp) => {
        let d = dayjs(timestamp * 1000).utcOffset(180).format('YYYY-MM-DD HH:mm:ss')
        return d;
    }

    const getTimeLeft = (targetDate) => {
        const now = new Date();
        const target = new Date(targetDate * 1000);


        const timeDifference = target - now;


        if (timeDifference <= 0) {
            return "0d 00:00:00";
        }

        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        return `${days}d ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
    }

    const getBundleVersion = useMemo(() => {
        let _bundle = bundle;

        if (item.hasOwnProperty('fixedBundle') && item.fixedBundle != null) {
            _bundle = item.fixedBundle;
        }

        if (_bundle == false) return null;

        const keys = Object.keys(_bundle.generations);
        const last_key = keys.pop();
        const prelast_key = keys.length > 0 ? keys.pop() : false;

        if(_bundle.generations[last_key] == undefined) return <><span>n/a</span></>
        return (
            <>
                <span>g{last_key}/v{_bundle.generations[last_key]['bundleVersion']}</span>
                {prelast_key !== false && <span>g{prelast_key}/v{_bundle.generations[prelast_key]['bundleVersion']}</span>}
            </>
        );
    }, [bundle, item.fixedBundle]);

    const getBundleInfo = () => {
        if (bundle == false) return false


        const keys = Object.keys(bundle.generations)
        const last_key = keys.pop()
        const prelast_key = keys.length > 0 ? keys.pop() : false

        return {
            generation: last_key,
            bundleVersion: bundle.generations[last_key]!=undefined? bundle.generations[last_key]['bundleVersion'] : '',
            prelastGeneration: prelast_key !== false ? prelast_key : '',
            prelastBundleVersion: prelast_key !== false ? bundle.generations[prelast_key]['bundleVersion'] : '',
        }

    }

    useEffect(() => {
        if (campaignType != null) {
            const updateTimeLeft = () => {
                setTimeLeftTill(getTimeLeft(item.distribution.till));
                setTimeLeftTillPlus(getTimeLeft(item.distribution.till + campaignType.extraDuration));
                setTimeLeftFrom(getTimeLeft(item.distribution.from));
                setTimeLeftFromMinus(getTimeLeft(item.distribution.from - campaignType.announceDuration));
            };

            updateTimeLeft(); // Обновить сразу при монтировании
            const timeleftInterval = setInterval(updateTimeLeft, 1000);
            return () => {
                clearInterval(timeleftInterval);
            };
        }
    }, [item.distribution.till, item.distribution.from]);

    useEffect(() => {
        setSelected(selectedList != undefined ? selectedList.indexOf(item.id) >= 0 : false)
    }, [selectedList, item.id])

    // function getEnvForEdit() {
    //     let env;
    //     if (rightPanel) {
    //         if (localStorage.getItem('env2Name')) {
    //             env = localStorage.getItem('env2Name')
    //         } else {
    //             env = 'staging'
    //         }
    //     } else {
    //         if (localStorage.getItem('envName') === 'PROD') {
    //             env = 'liveops'
    //         } else {
    //             env = localStorage.getItem('envName');
    //         }
    //     }
    //     return env;
    // }
    const handleCloneCampaignClick = () => {
        if(isTemplate)
            navigate(`/creator/templates/${item.id}?env=${env}&clone`)
        else
            navigate(`/creator/${appMode}/${item.id}?env=${env}&clone`)
    }

    const handleEditCampaignClick = () => {
        if(isTemplate) {
            navigate(`/creator/templates/${item.id}?env=${env}`)
        } else {
            navigate(`/creator/${appMode}/${item.id}?env=${env}`)
        }

    }

    const selectBundle = (id, item) => {
        setBundleHistoryDialog({
            isOpen: true,
            //bundle: id,
            item: item
        })
    }
    const openBundleHistoryDialog = () => {
        setBundleHistoryDialog({
            ...bundleHistoryDialog,
            isOpen: !bundleHistoryDialog.isOpen
        })
    }

    const warningText = useMemo(() => {
        if (item.warnings?.length > 0) {
            return (
                <List>
                    {item.warnings.map((warning, index) => (
                        <ListItem key={index}>{warning}</ListItem>
                    ))}
                </List>
            );
        }
        return null;
    }, [item.warnings]);
    
    const isPermanent = () => {
        return item.distribution.till == '32503680000'
    }


    const canChangeStatus = () => {
        if(env == "production" && prevStatus=="DEACTIVATED") return false;
        if(env == "production" && prevStatus=="COMPLETED" && status != "DEACTIVATED") return false;

        return true;
    }


    const getChangeStatusPopup = () => {

        if(!canChangeStatus()) {
            return <>You can not change DEACTIVATED status in production: <a target="_blank" href="https://belka-games.atlassian.net/wiki/spaces/BAC/pages/3992617217/Mephisto+Use+Cases#%D0%A1%D1%82%D0%B0%D1%82%D1%83%D1%81%D1%8B-%D0%B0%D0%B1-%D1%82%D0%B5%D1%81%D1%82%D0%BE%D0%B2">docs</a></>
        }


        if(status=="COMPLETED") return <div></div>

    }


    const handleTooltipClose = () => {
        setOpenTooltip(false);
    };

    const handleTooltipToggle = () => {
        setOpenTooltip(!openTooltip);
    };


    const handleExpandBody = useCallback((e) => {
        const ignoredTags = ['button', 'input'];
        const ignoredClasses = [
            'MuiChip-label',
            'campaign-actions',
            'campaign-item__checkbox',
            'campaign-status'
        ];

        if (
            typeof(e.target.className)=='string' &&
            !ignoredTags.includes(e.target.tagName.toLowerCase()) &&
            !ignoredClasses.some(cls => e.target.className.includes(cls))
        ) {
            setShowDetails(prev => !prev);
        }
    }, []);

    return (
        <>
            <Card className="campaign-item" key={item.id} style={{marginBottom: '3px'}}>
                <CardContent>


                    <div className="campaign-item__body" onClick={handleExpandBody}>
                        <div >
                            <div className="campaign-item__header">
                                <div className="campaign-item__checkbox">
                                    {onSelectItem != undefined && <Checkbox onChange={handleSelect} checked={selected}/>}
                                </div>

                                {(env=='liveops'||env=='production')&&(item.hasOwnProperty("warnings")&&item.warnings.length>0) && <div className="campaign-item__warning">
                                    <ClickAwayListener onClickAway={handleTooltipClose}>
                                        <div>
                                            <HtmlTooltip
                                                PopperProps={{
                                                    disablePortal: true,
                                                }}
                                                onClose={handleTooltipClose}
                                                open={openTooltip}
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                                title={warningText}
                                            >
                                                <FmdBadIcon
                                                    onClick={(e) => {
                                                        e.stopPropagation(); // Prevent the click from reaching ClickAwayListener
                                                        handleTooltipToggle(); // Toggle the tooltip open state
                                                    }}
                                                    color="warning"
                                                />
                                            </HtmlTooltip>
                                        </div>
                                    </ClickAwayListener>
                                </div>}

                                <div className="campaign-item__name">

                                    <div >


                                        <span onClick={(e) => {
                                            e.stopPropagation();
                                            handleCopyClick(item.id)
                                        }}>{item.id}</span>

                                        {showDetails&&(item.tags!=null&&item.tags.length>0)&&<div className={"campaign-item__name-tagsWrapper"}>{item.tags.map(item=>(<div className={"campaign-item__name-tag"}>{item}</div>))}</div>}

                                    </div>

                                </div>

                                <div className="campaign-gens">
                                    <div className="campaign-item__tags">
                                        {getBundleVersion}
                                    </div>
                                </div>

                                {env!=='production' && <div className="campaign-actions">
                                    <div>
                                        {!createDisabled ?
                                            <Link
                                                onClick={handleEditCampaignClick}
                                                  className="campaign-edit__link">
                                                <Tooltip title={"edit"}
                                                         placement="right">
                                                    <EditIcon fontSize="small"/>
                                                </Tooltip>
                                            </Link> :
                                            <Tooltip title={"editing disabled"}
                                                     placement="right">
                                                <EditIcon fontSize="small"/>
                                            </Tooltip>
                                        }
                                    </div>

                                    <div>
                                        {!createDisabled ?
                                            <Link
                                                onClick={handleCloneCampaignClick}
                                                  className="campaign-edit__link">
                                                <Tooltip title={"clone"} placement="right">
                                                    <CopyIcon fontSize="small"/>
                                                </Tooltip>
                                            </Link>
                                            :
                                            <Tooltip title={"create disabled"} placement="right">
                                                <CopyIcon fontSize="small"/>
                                            </Tooltip>
                                        }



                                    </div>
                                </div>}

                                <div className={"campaign-timewrap "+ timeClassName}>
                                    <div className="campaign-timeblock">
                                        <div className="campaign-item__starttime">
                                            <Tooltip
                                                title="Main Phase From">
                                                <span>{formatdate(item.distribution.from)}</span>
                                            </Tooltip>
                                        </div>
                                        <div className="campaign-item__countdown-timer">
                                            <Tooltip
                                                title="Timeleft before Main Phase">
                                                <span>{timeLeftFrom}</span>
                                            </Tooltip>
                                        </div>
                                        {showDetails &&
                                            <>
                                                <>
                                                    <div className="campaign-item__starttime">
                                                        <Tooltip
                                                            title="Announce From">
                                                            <span>{formatdate(item.distribution.from - campaignType?.announceDuration)}</span>
                                                        </Tooltip>
                                                    </div>
                                                    <div className="campaign-item__countdown-timer">
                                                        <Tooltip
                                                            title="Timeleft before Announce">
                                                            <span>{timeLeftFromMinus}</span>
                                                        </Tooltip>
                                                    </div>

                                                </>

                                            </>
                                        }

                                    </div>

                                    <div className="campaign-timeblock">

                                        <div className="campaign-item__endtime">
                                            <Tooltip
                                                title="Main Phase Till">
                                                <span>{formatdate(item.distribution.till)}</span>
                                            </Tooltip>
                                        </div>
                                        <div className="campaign-item__countdown-timer">
                                            <Tooltip
                                                title="Timeleft before Main Phase">
                                                <span>{!isPermanent()?timeLeftTill:<>-</>}</span>
                                            </Tooltip>
                                        </div>
                                        {showDetails&&!isPermanent() &&
                                            <>
                                                <div className="campaign-item__endtime">
                                                    <Tooltip
                                                        title="Extra Time Till">
                                                        <span>{formatdate(item.distribution.till + campaignType?.extraDuration)}</span>
                                                    </Tooltip>
                                                </div>
                                                <div className="campaign-item__countdown-timer">
                                                    <Tooltip
                                                        title="Timeleft left before Extra Time">
                                                        <span>{timeLeftTillPlus}</span>
                                                    </Tooltip>
                                                </div>
                                            </>
                                        }

                                    </div>
                                    {showDetails&&<div style={{"clear":"both"}}>Distribution type: <b>{item.distribution.type}</b></div>}

                                </div>

                                <div className="campaign-status">
                                    <StatusSelector
                                        status={status}
                                        statusList={statusList}
                                        onChange={handleChangeStatus}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            return true;
                                        }}
                                    />

                                </div>

                            </div>

                        </div>

                        {isTemplate && <><div className="campaign-details"><small><b>Connected campaigns</b></small></div>
                            <small>{item.campaigns.map((_c, index)=> (
                                <div>{_c}</div>
                            ))}</small></>}

                        {showDetails && <div className="campaign-details">



                            <div className="campaign-audience">
                                {item.audiences.map((audience, index) => (
                                    <Audience key={index} audience={audience}>Audience {(index + 1)}<br /></Audience>
                                ))}
                            </div>
                            <div className="campaign-audience right">
                                {item.fickle==true&&<Chip size={"small"} color="warning" label={"Check audiences Every Time (fickle)"} />}
                                {item.repeatable==true&&<Chip sx={{ml:0.2}} size={"small"} color="warning" label={"Allow repeat after finish (repeatable)"} />}
                            </div>


                            <div className="campaign-item__footer">
                                {bundle != undefined &&
                                    <Button variant="outlined" onClick={() => (setBundlesOpen(true))} sx={{mr: 1}}>
                                        Bundles
                                    </Button>}
                                <Button variant="outlined" onClick={() => (setJsonOpen(true))} sx={{mr: 1}}>
                                    JSON
                                </Button>
                                <Button variant="outlined" onClick={() => (setHistoryOpen(true))} sx={{mr: 1}}>
                                    History
                                </Button>


                            </div>
                        </div>}
                    </div>
                </CardContent>
            </Card>

            {(bundle != undefined && bundlesOpen) &&
                <CampaignBundleDialog key={`cbd`+item.id} selectBundle={selectBundle} fixedBundle={item.hasOwnProperty('fixedBundle')&&item.fixedBundle!=null} item={item.hasOwnProperty('fixedBundle')&&item.fixedBundle!=null?item.fixedBundle:bundle} open={bundlesOpen} setOpen={setBundlesOpen} campaign={item.id}
                                      env={env}></CampaignBundleDialog>}
            {(historyOpen) &&
                <HistoryDialog key={`h`+item.id} env={env} item={item} open={historyOpen} setOpen={setHistoryOpen}></HistoryDialog>}
            {open&&<CampaignDetailsDialog key={`cdd`+item.id} item={item} open={open} setOpen={setOpen}></CampaignDetailsDialog>}
            {jsonOpen&&<CampaignJsonDialog key={`cjd`+item.id} item={item} open={jsonOpen} setOpen={setJsonOpen}></CampaignJsonDialog>}
            {bundleHistoryDialog.isOpen&&<CampaignBundleRollbackDialog key={`cbrd`+item.id} env={env} bundle={item.bundleId} selectedBundle={bundleHistoryDialog} open={bundleHistoryDialog.isOpen} setOpen={openBundleHistoryDialog} ></CampaignBundleRollbackDialog>}

            {showConfirmStatusChange&&<Dialog
                open={showConfirmStatusChange}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Changing status
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You want to change status from <strong>{prevStatus}</strong> to <strong>{status}</strong>
                        <br/>for <strong>{item.id}</strong>

                        <div>{getChangeStatusPopup()}</div>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelSaveStatus}>Cancel</Button>

                    {canChangeStatus()&&<LoadingButton variant="contained" onClick={confirmSaveStatus}
                                   loading={responseObj.isLoading}>
                        Save
                    </LoadingButton>}
                </DialogActions>
            </Dialog>}
        </>
    )

}

export default React.memo(CampaignItem);
