import {
    Box,
    Button, Chip,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, Radio,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {
    useGetBundleHistoryQuery, useGetHistoryQuery,
    useGetPlatformsInfoQuery, useRollbackAllBundleMutation,
    useRollbackBundleMutation
} from "../../core/reducers/campaign";
import Loading from "../Loading";
import clipboardCopy from "clipboard-copy";
import dayjs from "dayjs";
import {isAdmin, isProdAdmin, isProdEnv, LIVEOPS_ENV, PROD} from "../Utils/CommonUtils";
import {useSelector} from "react-redux";

import {SnackBarContext} from "../../core/context/snackBarProvider";
import {Alert, LoadingButton} from "@mui/lab";
import {formatdate} from "../../constants";
import RollbackResponseDialog from "./RollbackResponseDialog";
import {useCallback, useState} from "react";
import AsTable from "../AsTable";
import ReactJsonViewCompare from 'react-json-view-compare';
import ReactJson from "react-json-view";
import LogItem from "../AdminPanel/LogItem";

export default function HistoryDialog({item, open, setOpen, env}) {

    const {data, isLoading, isError, error} = useGetHistoryQuery({environment: env, event_id: item.id}, {skip: !open});

    const [openDiff, setOpenDiff] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const handleCloseDiff = () => {
        setOpenDiff(false);
        setOpenInfo(false);
        setSelectedRow(null);
    };

    const groupByTransferId = (data) => {
        const logs = JSON.parse(JSON.stringify(data.logs));
        const transferIdMap = new Map();

        const result = logs.filter(log => {
            const transferId = log.info.transferId;

            if (!transferId) {
                return true;
            }

            if (!transferIdMap.has(transferId)) {
                transferIdMap.set(transferId, log);
                return true;
            } else {
                const existingLog = transferIdMap.get(transferId);
                if (!existingLog.related) {
                    existingLog.related = [];
                }
                existingLog.related.push(log);
                return false;
            }
        });

        return { logs: result };
    }


    const onSelectedRow = useCallback((row) => {
        setSelectedRow(row);
    }, []);

    const onOpenDiff = useCallback((value) => {
        setOpenDiff(value);
    }, []);

    const onOpenInfo = useCallback((value) => {
        setOpenInfo(value);
    }, []);



    const transformedData = data==undefined?undefined:groupByTransferId(data)

    console.log('transformedData')
    console.log(transformedData)

    return (
        <><Dialog
            maxWidth={'xl'}
            onClose={() => setOpen(false)} open={open}>
            <DialogTitle>History of <b>{item.id}</b> in <b>{env}</b></DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => setOpen(false)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon/>
            </IconButton>
            <DialogContent>


                <Loading isLoading={isLoading} isError={isError} error={error}>
                    {transformedData != undefined ?
                        <>
                            {transformedData.logs.map(item => (
                                <LogItem item={item} setSelectedRow={onSelectedRow} setOpenDiff={onOpenDiff} setOpenInfo={onOpenInfo} />
                            ))}
                        </>
                        : <Alert severity={'info'}>no data for {item.id}</Alert>
                    }
                </Loading>
            </DialogContent>
            <DialogActions>

                <Button variant="contained"
                        onClick={()=>{setOpen(false)}}>
                    Close
                </Button>

            </DialogActions>
        </Dialog>

            {openDiff&&<Dialog open={openDiff} onClose={handleCloseDiff} maxWidth="md" fullWidth>
                <DialogTitle>JSON Diff</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseDiff}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogContent>
                    {!openInfo&&<ReactJsonViewCompare newData={selectedRow.newData} oldData={selectedRow.oldData} />}
                    {openInfo&&<ReactJson
                        theme={'bright:inverted'}
                        src={selectedRow.info}
                        iconStyle={'square'}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        enableClipboard={false}
                    />}
                </DialogContent>
                <DialogActions>


                    <Button variant="outlined"
                            onClick={handleCloseDiff}>
                        Close
                    </Button>

                </DialogActions>
            </Dialog>}
        </>
    );
}