import React, {useCallback, useState} from 'react';
import {
    Alert,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    Table,
    TableBody,
    TableCell,
    TableRow, DialogActions,
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { getEnvName } from "../Utils/CommonUtils";
import {useGetHistoryAllQuery, useGetHistoryQuery} from "../../core/reducers/campaign";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "../Loading";

import ReactJsonViewCompare from 'react-json-view-compare';
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import ReactJson from "react-json-view";
import LogItem from "./LogItem";

export default function Logs() {
    const [searchParams] = useSearchParams();
    const sourceMode = useSelector((state) => state.data.SourceMode);
    const envName = getEnvName('env', sourceMode, searchParams);

    const { data, isLoading, isError, error } = useGetHistoryAllQuery({ environment: envName });
    const [openDiff, setOpenDiff] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);
    const [selectedRow, setSelectedRow] = useState(false);

    const columns = [
        { field: 'userName', headerName: 'Username', width: 150 },
        { field: 'userEmail', headerName: 'Email', width: 150 },
        { field: 'creationDate', headerName: 'Created At', width: 180 },
        { field: 'adminAction', headerName: 'Action', width: 180 },
        { field: 'eventId', headerName: 'ID', width: 100 },
        { field: 'eventType', headerName: 'Type', width: 150 },

        {
            field: 'diff',
            headerName: 'diff',
            width: 250,
            renderCell: (params) => {
                return <Button variant={"outlined"} onClick={() => {
                    setSelectedRow({
                        oldData: params.row.oldData,
                        newData: params.row.newData,
                        info: params.row.info,

                    });
                    setOpenDiff(true);
                }}>Show Diff</Button>
            },
        },
        {
            field: 'info',
            headerName: 'Details',
            width: 250,
            renderCell: (params) => {

                if(params.row.info!=null && Object.keys(params.row.info).length>0) return <Button variant={"outlined"} onClick={() => {
                    setSelectedRow({
                        oldData: params.row.oldData,
                        newData: params.row.newData,
                        info: params.row.info,
                    });
                    setOpenDiff(true);
                    setOpenInfo(true);
                }}>Show Info</Button>

                return ''
            },
        },
    ];

    const rows = data
        ? data.logs.map((item, index) => ({
            id: index,
            userName: item.userName,
            userEmail: item.userEmail,
            creationDate: dayjs(item.creationDateSeconds * 1000)
                .utcOffset(180)
                .format('YYYY-MM-DD HH:mm:ss'),
            eventId: item.eventId,
            adminAction: item.adminAction,
            eventType: item.eventType,
            oldData: item.oldData,
            newData: item.newData,
            info: item.info,
        }))
        : [];

    const handleCloseDiff = () => {
        setOpenDiff(false);
        setOpenInfo(false)
    };

    const onSelectedRow = useCallback((row) => {
        setSelectedRow(row);
    }, []);

    const onOpenDiff = useCallback((value) => {
        setOpenDiff(value);
    }, []);

    const onOpenInfo = useCallback((value) => {
        setOpenInfo(value);
    }, []);

    return (
        <>
            <Typography variant="h4" gutterBottom>
                Logs
            </Typography>

            <Loading isLoading={isLoading} isError={isError} error={error}>
                {data !== undefined&&data.logs.map(item => (
                    <LogItem key={item.id} item={item} setSelectedRow={onSelectedRow}
                             setOpenDiff={onOpenDiff} setOpenInfo={onOpenInfo} />
                ))}

                {data !== undefined ? (
                    <div style={{ height: 600, width: '100%' }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10, 25, 50]}
                            disableSelectionOnClick
                        />
                    </div>
                ) : (
                    <Alert severity="info">No logs</Alert>
                )}
            </Loading>

            {openDiff&&<Dialog open={openDiff} onClose={handleCloseDiff} maxWidth="md" fullWidth>
                <DialogTitle>JSON Diff</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseDiff}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogContent>
                    {!openInfo&&<ReactJsonViewCompare newData={selectedRow.newData} oldData={selectedRow.oldData} />}
                    {openInfo&&<ReactJson
                        theme={'bright:inverted'}
                        src={selectedRow.info}
                        iconStyle={'square'}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        enableClipboard={false}
                    />}
                </DialogContent>
                <DialogActions>


                    <Button variant="outlined"
                            onClick={handleCloseDiff}>
                        Close
                    </Button>

                </DialogActions>
            </Dialog>}
        </>
    );
}

// DataCell component to render oldData and newData fields
function DataCell({ value }) {
    const [open, setOpen] = useState(false);

    const isObject = value && typeof value === 'object' && !Array.isArray(value);

    const handleExpandClick = (e) => {
        e.stopPropagation(); // Prevents DataGrid row selection
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getValueString = (val) => {
        if (typeof val === 'object') {
            if(val == null) return '-';
            return JSON.stringify(val, null, 2);
        }
        return String(val);
    };

    return (
        <>
            {isObject ? (
                <>
                    <Button variant="outlined" size="small" onClick={handleExpandClick}>
                        Expand
                    </Button>
                    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                        <DialogTitle>Data</DialogTitle>
                        <DialogContent>
                            <Table>
                                <TableBody>
                                    {Object.entries(value).map(([key, val]) => (
                                        <TableRow key={key}>
                                            <TableCell>{key}</TableCell>
                                            <TableCell>{getValueString(val)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </DialogContent>
                    </Dialog>
                </>
            ) : (
                <Typography variant="body2">{getValueString(value)}</Typography>
            )}
        </>
    );
}