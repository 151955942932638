import React, {useState, useEffect, useRef} from 'react';
import CampaignGroup from "../Campaign/CampaignGroup";
import {useGetAllCampaignsQuery} from "../../../core/reducers/campaign";
import Loading from "../../Loading";
import {sortCampaign} from "../../Utils/CommonUtils";
import {CampaignItemContext} from "../../../core/context/context";
import {Alert} from "@mui/lab";
import {Card} from "@mui/material";
import {useGetAllTemplatesQuery} from "../../../core/reducers/templates";


export default function CampaignTemplateFrame({
                                          env,
                                          onSelectItem,
                                          filterText,
                                          selectedList,
                                          filterCampaignType,
                                          filterStatuses,
                                          rightPanel,
                                          createDisabled,
                                          dateFilter,
                                          filterType

                                      }) {


    const [showAllGroups, setShowAllGroups] = useState(null);
    const [showRainbow, setShowRainbow] = useState(window.localStorage.getItem("show_rainbow_" + env) == null ? false : window.localStorage.getItem("show_rainbow_" + env) == 'true')


    const query = {}
    query.env = env
    if(dateFilter!=undefined && dateFilter.from!= '' && dateFilter.to != '') {

        query.from = Math.round(new Date(dateFilter.from).setHours(0,0,0,0)/1000);
        query.till = Math.round(new Date(dateFilter.till).setHours(23,59,59,999)/1000);

    }

    const {
        data: campaigns,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error,
        refetch
    } = useGetAllTemplatesQuery(query, {
        pollingInterval: 5000,
    });

    const campaignTypesByType = campaigns==undefined ? {} : campaigns?.types.reduce((acc, item) => {
        acc[item.readableType] = item;
        return acc;
    }, {});
    const campaignsByType = campaigns == undefined ? [] : campaigns.templates.reduce((acc, campaign) => {
        const type = campaign.type;

        if (filterText !== '' && campaign.id.toLowerCase().indexOf(filterText.toLowerCase()) < 0) return acc;

        if (filterType !== undefined && filterType !== 'All' && filterType != null) {
            if (campaign.type != filterType) return acc;
        }

        if (filterCampaignType !== '') {
            let campaignType = campaignTypesByType[campaign.type]
            if (campaignType.contentType !== filterCampaignType) return acc;
            //if(filterCampaignType!= '' && campaign.id.toLowerCase().indexOf(filterText.toLowerCase()) < 0) return acc;
        }

        if (filterStatuses !== undefined && filterStatuses.length > 0) {
            if (!filterStatuses.includes(campaign.status)) return acc;
        }

        if (!acc[type]) {
            acc[type] = [];
        }
        acc[type].push(campaign);

        return acc;
    }, {});

    const getExpandTitle = () => {
        if(showAllGroups == true) return "expand all"
        return "collapse all"
    }
    const getRainbowTitle = () => {
        if(showRainbow == false) return "rainbow"
        return "de-rainbow"
    }

    const prevPropsRef = useRef();
    const prevProps = prevPropsRef.current;
    useEffect(() => {

        if(prevProps==undefined || (prevPropsRef.current != env && error==undefined && !isLoading && !isFetching)) {
            prevPropsRef.current = env
        }
    }, [env, isLoading, isFetching, error]);


    const isNotError = (error==''||error==undefined) || (error?.data?.message?.indexOf('Cursor')>0 && prevPropsRef.current==env)
    const shouldShowContent = (!isLoading&&prevPropsRef.current==env || (prevPropsRef.current!=env&&!isFetching)) && isNotError &&(( !isLoading && !isError) || (prevPropsRef.current==env))
    const shouldShowLoading = ((error!=''&&error!=undefined&&error?.data?.message?.indexOf('Cursor')==-1) || isLoading || (isError && prevPropsRef.current!=env && error?.data?.message?.indexOf('Cursor')==-1) || (isFetching && prevPropsRef.current!=env && prevPropsRef.current!=undefined))

    return (
        <div>
            {shouldShowLoading && <Card  sx={{mt:3.5}} severity="info"><Loading env={env} isLoading={isLoading||isFetching} isError={isError} error={error} refetch={refetch}></Loading></Card>}

            {shouldShowContent&&Object.keys(campaignsByType).length == 0 && <Alert sx={{mt:3.5}} severity="info">No templates</Alert>}
            {shouldShowContent&&Object.keys(campaignsByType).length > 0 && <>

                <div className="collapse-all-link" onClick={() => {setShowAllGroups(!showAllGroups);}}>{getExpandTitle()}</div>
               &nbsp;
                <div className="collapse-all-link" onClick={() => {
                    setShowRainbow(!showRainbow);
                    window.localStorage.setItem("show_rainbow_" + env, !showRainbow)
                }}>{getRainbowTitle()}</div>

                {Object.keys(campaignsByType).sort().map((type) => (
                    <CampaignItemContext.Provider
                        key={type}
                        value={{
                            isTemplate: true,
                            env,
                            selectedList,
                            onSelectItem,
                            bundles: campaigns.bundles,
                            createDisabled,
                            type,
                            campaigns: campaigns,
                            types: campaignTypesByType,
                            campaignType: campaignTypesByType[type],
                            items: campaignsByType[type].sort(sortCampaign)
                        }}>
                        <CampaignGroup showRainbow={showRainbow} showAllGroups={showAllGroups} rightPanel={rightPanel} />
                    </CampaignItemContext.Provider>
                ))}
            </>}

        </div>
    );
};
