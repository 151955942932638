import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import {configs, getDomainAlias} from "../../constants";

import {AB_TESTS, CAMPAIGNS, isTokenExpired} from "../../components/Utils/CommonUtils";

import {useMsal} from "@azure/msal-react";

//
// const { createProxyMiddleware } = require('http-proxy-middleware');
//
// module.exports = function(app) {
//     app.use(
//         '/api',
//         createProxyMiddleware({
//             target: '//localhost:3000',
//             changeOrigin: true,
//         })
//     );
// };

const dynamicBaseQuery = async (args, api, extraOptions) => {
    const urlEnd = typeof args === 'string' ? args : args.url
    // construct a dynamically generated portion of the url
    let adjustedUrl = ''

    let env = '';
    let baseUrl = 'https://cross-campaign.backend-stage.belka-games.com/campaign'
    if(args.hasOwnProperty('env') && ['production','local','staging'].indexOf(args.env)!==-1) {
        env = args.env
    }

    if(env==='production') {
        if (extraOptions?.type === AB_TESTS) {
            baseUrl = configs[getDomainAlias()].abTestProdUrl
        } else {
            baseUrl = configs[getDomainAlias()].prodUrl
        }
    } else {
        if (extraOptions?.type === AB_TESTS) {
            baseUrl = configs[getDomainAlias()].abTestUrl
        } else {
            baseUrl = configs[getDomainAlias()].baseUrl
        }
    }

    adjustedUrl = baseUrl + urlEnd

    const adjustedArgs =
        typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl }

    try {
        const result = await rawBaseQuery(adjustedArgs, api, extraOptions)

        if (result.error && result.error.status === 401) {
            console.error("Unauthorized access detected");
            localStorage.removeItem("azureToken");

            const onTokenExpired = new CustomEvent('onTokenExpired');
            window.dispatchEvent(onTokenExpired);
        }

        return result
    } catch (error) {
        console.error("Error during query:", error);
        throw error;
    }
}

const api = createApi({
    baseQuery: dynamicBaseQuery,
    endpoints: () => ({}),
})

const getIdToken = async () => {

    let accessToken = localStorage.getItem("azureToken")
    if(!accessToken || isTokenExpired(accessToken)) {

        //
        // const response = await msalInstance.acquireTokenSilent({
        //     account: account
        // });
        // accessToken = response.accessToken;
    }

    return accessToken
}

const rawBaseQuery = fetchBaseQuery({
    prepareHeaders: async (headers) => {
        const token = localStorage.getItem("azureToken")
        if (token) headers.set("Authorization", `Bearer ${token}`)
        return headers
    }
})

export default api
