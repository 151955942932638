import dayjs from "dayjs";
import {Button, Checkbox, FormControlLabel, FormGroup, Link, Table, TableCell, TableRow} from "@mui/material";
import {memo, useState} from "react";
import Audience from "../Publisher/Audience";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SyncAltIcon from '@mui/icons-material/SyncAlt';

const LogItem = memo(function({item, setSelectedRow, setOpenDiff, setOpenInfo}) {

    const [showDetails, setShowDetails] = useState(false)

    const STATUS_NAME = {
        "STATUS_CHANGING": "changed status to",
        "EVENT_CREATION": "created",
        "EVENT_UPDATE": "edited",
        "EVENT_CREATION_BY_TRANSFER": "created by transfer",
        "EVENT_UPDATE_BY_TRANSFER": "updated by transfer",
        "EVENT_CREATION_BY_TEMPLATE": "created campaign from template",
        "BUNDLE_UPDATE": "changed bundles",
        "BUNDLE_CREATION": "bundle created",
        "BUNDLE_DELETE": "bundle deleted",
        "BUNDLE_ROLLBACK": "rolled back",
        "EVENT_TRANSFER": "transfered",
    }

    const findChanges = (json1, json2) => {
        const changes = [];

        // Проверяем изменения в существующих ключах
        for (const [key, value1] of Object.entries(json1.generations)) {
            if (json2.generations.hasOwnProperty(key)) {
                const value2 = json2.generations[key];
                if (value1.bundleVersion !== value2.bundleVersion) {
                    changes.push({
                        generation: key,
                        changeType: 'modified',
                        version: {
                            old: value1.bundleVersion,
                            created: value2.bundleVersion
                        }
                    });
                }
            }
        }

        // Проверяем добавленные ключи во втором объекте
        for (const key in json2.generations) {
            if (!json1.generations.hasOwnProperty(key)) {
                changes.push({
                    generation: key,
                    changeType: 'added',
                    version: {
                        created: json2.generations[key].bundleVersion
                    }
                });
            }
        }

        let last = json2.generations[Object.keys(json2.generations).pop()]
        return changes.length ? changes : [{
            generation:last.generation,
            changeType: 'added',
            version: {
                created: last.clientVersion.version
            }}
        ];
    }

    const getInfo = (item) => {
        //return JSON.stringify(item)
        let diff = null

        switch(item.adminAction) {
            case 'BUNDLE_DELETE':
                diff = findChanges(item.newData, item.oldData)
                return <><span className={"tag"}>g{diff[0].generation}/v{(diff[0].version?.created)}</span> in <b className={item.info.environment}>{item.info.environment}</b> environment</>
            case 'BUNDLE_ROLLBACK':
                diff = findChanges(item.oldData, item.newData)
                return <>bundle to <span className={"tag"}>g{diff[0].generation}/v{(diff[0].version?.created)}</span> in <b className={item.info.environment}>{item.info.environment}</b> environment</>
            case 'EVENT_CREATION':
                if(item.hasOwnProperty('info')&&item.info?.hasOwnProperty('original')) {
                    return <>clone from <Link target={"_blank"} href={'/creator/campaigns/'+item.info.original}><b>{item.info.original}</b></Link></>
                }
                return <></>
            case 'EVENT_TRANSFER':
                return <>from <b className={item.newData?.source}>{item.newData?.source}</b> to <b className={item.newData?.target}>{item.newData?.target}</b></>;
            case 'EVENT_UPDATE_BY_TRANSFER':
            case 'EVENT_CREATION_BY_TRANSFER':
                return <>from <b className={item.info?.source}>{item.info?.source}</b> to <b className={item.info?.target}>{item.info?.target}</b></>;
            case 'BUNDLE_UPDATE':
                diff = findChanges(item.oldData, item.newData)
                if(!diff[0].hasOwnProperty('version')) {
                    console.log(item.id)
                }
                const tags = diff.map((item, index) => (
                    <span key={index} className={"tag"}>
                        g{item.generation}/v{item.version?.created}
                    </span>
                ));
                return <div>to {tags.reduce((prev, curr) => [prev, ', ', curr])} by another transfer</div>
            case 'STATUS_CHANGING':
                return <span
                    className={"status status-" + item.newData.status.toLowerCase()}>{item.newData.status}</span>
            case 'EVENT_CREATION_BY_TEMPLATE':
                return <Link href={"/creator/templates/"+item.info.campaignTemplateId} variant="body2">
                    <b>{item.info.campaignTemplateId}</b>
                </Link>
        }

    }

    const getChangedRows = (newData, oldData) => {
        const changes = [];
        for (const key in newData) {
            if (JSON.stringify(newData[key]) !== JSON.stringify(oldData[key])) {
                changes.push(key);
            }
        }
        return changes;
    };

    const getTableRows = (item) => {
        const { newData, oldData, adminAction } = item;
        const allRows = [
            { label: 'Type', key: 'type', value: newData.type },
            { label: 'Bundle ID', key: 'bundleId', value: newData.bundleId },
            { label: 'Group ID', key: 'groupId', value: newData.groupId },
            { label: 'Priority', key: 'priority', value: newData.priority },
            { label: 'Tags', key: 'tags', value: newData.tags!=undefined?newData.tags.join(', '):'' },
            { label: 'Tag', key: 'tag', value: newData.tag},
            {
                label: 'Status',
                key: 'status',
                value: (
                    <>
                        <span className={`status status-${newData.status.toLocaleLowerCase()}`}>{newData.status}</span>
                    </>
                ),
            },
            {
                label: 'Distribution',
                key: 'distribution',
                value: (
                    <div>
                        <div>
                            <span>Type: {newData?.distribution?.type}</span>&nbsp;&nbsp;&nbsp;
                            <span>Duration: {newData?.distribution?.duration / 3600}h</span>&nbsp;&nbsp;&nbsp;
                        </div>
                        <div>
                            {dayjs(newData?.distribution?.from * 1000).utcOffset(180).format('YYYY-MM-DD HH:mm:ss')}
                            &nbsp;-&nbsp;
                            {dayjs(newData?.distribution?.till * 1000).utcOffset(180).format('YYYY-MM-DD HH:mm:ss')}
                        </div>
                    </div>
                ),
            },
            {
                label: 'Audience',
                key: 'audiences',
                value: newData?.audiences?.map((audience, index) => (
                    <Audience key={index} audience={audience}>
                        Audience {(index + 1)}<br />
                    </Audience>
                )),
            },
            {
                label: 'Advanced flags',
                key: 'advancedFlags',
                value: (
                    <FormGroup row>
                        <FormControlLabel control={<Checkbox checked={newData.fickle} />} label="Check Audience Every Time (fickle)" />
                        <FormControlLabel control={<Checkbox checked={newData.repeatable} />} label="Allow repeat after finish (repeatable)" />
                    </FormGroup>
                ),
                changed: newData.fickle !== oldData.fickle || newData.repeatable !== oldData.repeatable,
            },
        ];

        if (adminAction === 'EVENT_UPDATE' || adminAction === 'EVENT_UPDATE_BY_TRANSFER' || adminAction === 'STATUS_CHANGING') {
            const changedKeys = getChangedRows(newData, oldData);
            return allRows.filter(row => changedKeys.includes(row.key) || row.changed);
        }

        return allRows;
    };

    console.log(item.adminAction)

    const infoBtn = item.info != null && Object.keys(item.info).length > 0 &&
        <Button variant={"outlined"} onClick={() => {
            setSelectedRow({
                oldData: item.oldData,
                newData: item.newData,
                info: item.info,
            });
            setOpenDiff(true);
            setOpenInfo(true);
        }}>Show Info</Button>

    const diffBtn = <Button
        size={"small"}
        variant={"outlined"}
        onClick={() => {
            setSelectedRow({
                oldData: item.oldData,
                newData: item.newData,
                info: item.info,
            });
            setOpenDiff(true);
        }}
    >
        json diff
    </Button>

    return (
        <>
            <div className={"history-item " + (showDetails?"expanded":"")}>
                <div
                    className={"history-item__date"}>{dayjs(item.creationDateSeconds * 1000).utcOffset(180).format('YYYY-MM-DD HH:mm:ss')}</div>

                {['EVENT_UPDATE_BY_TRANSFER','EVENT_CREATION_BY_TRANSFER'].includes(item.adminAction)&&<div><SyncAltIcon fontSize={"small"} style={{padding: 0, margin:0}} /></div>}

                <div className={"history-owner "+ item.userName.toLowerCase()}>{item.userName}</div>

                <div className={item.adminAction.toLowerCase()}>{STATUS_NAME.hasOwnProperty(item.adminAction) ? STATUS_NAME[item.adminAction] : item.adminAction}</div>

                {['EVENT_CREATION','EVENT_UPDATE'].includes(item.adminAction)&&<div>{item.eventType.toLowerCase()}</div>}

                <div>{getInfo(item)}</div>
                {false&&<div>{item.eventId}</div>}

                <div className={"history-details"}>
                    {![''].includes(item.adminAction) ? <Button
                        size={"small"}
                        variant={"outlined"}
                        onClick={() => setShowDetails(!showDetails)}
                    >{!showDetails?<span>Details</span>:<span>Close</span>}</Button> : diffBtn}
                </div>


            </div>
            {showDetails &&<div className={"history-item"}>
                { ['STATUS_CHANGING','EVENT_CREATION', 'EVENT_UPDATE', 'EVENT_UPDATE_BY_TRANSFER', 'EVENT_CREATION_BY_TRANSFER', 'EVENT_CREATION_BY_TEMPLATE'].includes(item.adminAction) && (<>
                        <Table>
                            {getTableRows(item).map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ width: "134px" }}><b>{row.label}:</b></TableCell>
                                    <TableCell>{row.value}</TableCell>
                                </TableRow>
                            ))}

                        </Table>

                    </>
                )}

                {!['STATUS_CHANGING','EVENT_TRANSFER', 'EVENT_CREATION','EVENT_UPDATE','EVENT_UPDATE_BY_TRANSFER','EVENT_CREATION_BY_TRANSFER','EVENT_CREATION_BY_TEMPLATE'].includes(item.adminAction)&&<>
                    <Table>
                        <TableRow>
                            <TableCell style={{"width":"134px"}}><b>Bundle ID:</b></TableCell>
                            <TableCell>{item.newData.id}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{"width":"134px"}}><b>Bundles:</b></TableCell>
                            <TableCell>{Object.keys(item.newData.generations).map((key) => {
                                return <div style={{marginBottom:"5px"}}>
                                    <span className={"tag"}>g{key}/v{item.newData.generations[key].bundleVersion}</span>&nbsp;
                                    for {item.newData.generations[key].clientVersion.version}&nbsp;
                                    {item.newData.generations[key].data?.buildLink!=null&&<Link className={"button-outlined"} href={item.newData.generations[key].data?.buildLink} target={"_blank"}>Show in Teamcity</Link>}
                                    <div className={"history-details__platforms"}>
                                        {item.newData.generations[key].data!==null&&Object.keys(item.newData.generations[key].data?.platformToCatalogFile).join(', ')}
                                    </div>

                                </div>
                            })}</TableCell>
                        </TableRow>
                    </Table>
                </>}

                <div style={{ float: 'right' }}>
                    <div >
                        {diffBtn}
                    </div>
                </div>
            </div>}

            {item.hasOwnProperty('related')&&item.related.length>0&&<div className={"history-item__related"}>
                {item.related.map(_item => (
                    <LogItem item={_item} setSelectedRow={setSelectedRow} setOpenDiff={setOpenDiff} setOpenInfo={setOpenInfo} />
                ))}
            </div>}

        </>
    )
})

export default LogItem