import React, {useState, useEffect, useRef} from 'react';
import CampaignGroup from "./CampaignGroup";
import {useGetAllCampaignsQuery} from "../../../core/reducers/campaign";
import Loading from "../../Loading";
import {sortCampaign, sortCampaignByDistribution} from "../../Utils/CommonUtils";
import {CampaignItemContext} from "../../../core/context/context";
import {Alert} from "@mui/lab";
import {Card} from "@mui/material";
import {getDomainAlias} from "../../../constants";


export default function CampaignFrame({
                                          env,
                                          onSelectItem,
                                          filterText,
                                          selectedList,
                                          filterCampaignType,
                                          filterStatuses,
                                          filterType,
                                          rightPanel,
                                          createDisabled,
                                          dateFilter

                                      }) {


    const [showAllGroups, setShowAllGroups] = useState(null);
    const [sortByType, setSortByType] = useState(true);
    const [showRainbow, setShowRainbow] = useState(window.localStorage.getItem("show_rainbow_" + env) == null ? false : window.localStorage.getItem("show_rainbow_" + env) == 'true')

    const query = {}
    query.env = env
    if(dateFilter!=undefined && dateFilter.from!= '' && dateFilter.to != '') {

        query.from = Math.round(new Date(dateFilter.from).setHours(0,0,0,0)/1000);
        query.till = Math.round(new Date(dateFilter.till).setHours(23,59,59,999)/1000);

    }

    const {
        data: campaigns,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error,
        refetch
    } = useGetAllCampaignsQuery(query, {
        pollingInterval: 5000,
    });


    const timeGroups = [
        'All types by Campaign Start',
        'Infinite Campaigns (float)',
        'Infinite Campaigns (permanent)'
    ]

    function getPriority(item) {
        const campaignType = campaignTypesByType[item.type]

        let current_time = new Date().getTime() / 1000;

        if (current_time < (item.distribution.from - campaignType.announceDuration)) return 1; // aqua
        if (
            current_time >= (item.distribution.from - campaignType.announceDuration) &&
            current_time < item.distribution.from
        ) return 2; // lightgreen
        if (
            current_time >= item.distribution.from &&
            current_time < item.distribution.till
        ) return 3; // green
        if (
            current_time >= item.distribution.till &&
            current_time < (item.distribution.till + campaignType.extraDuration)
        ) return 4; // yellow
        if (current_time >= (item.distribution.till + campaignType.extraDuration)) return 5; // red
    }

    function sortItemsByTime(a, b) {
            const priority = getPriority(a) - getPriority(b);
            if(priority == 0) {
                return (a.distribution.from - b.distribution.from) < 0 ? 1 : -1
            }
            return priority
    }

    const getSorted = (campaigns) => {
        return campaigns.campaigns.reduce((acc, campaign) => {

            if (filterText !== '' && campaign.id.toLowerCase().indexOf(filterText.toLowerCase()) < 0) return acc;

            if (filterCampaignType !== '') {
                let campaignType = campaigns.types.filter(item => item.readableType === campaign.type)[0]
                if (campaignType.contentType !== filterCampaignType) return acc;
                //if(filterCampaignType!= '' && campaign.id.toLowerCase().indexOf(filterText.toLowerCase()) < 0) return acc;
            }

            if (filterStatuses !== undefined && filterStatuses.length > 0) {
                if (!filterStatuses.includes(campaign.status)) return acc;
            }

            if (filterType !== undefined && filterType !== 'All' && filterType != null) {
                if (campaign.type != filterType) return acc;
            }

            let type = timeGroups[0];
            if(campaign.distribution.type=='fixed') {}
            if(campaign.distribution.type=='float' && campaign.distribution.till >= 32503680000) {
                type = timeGroups[1]
            } else if(campaign.distribution.type=='permanent' && campaign.distribution.till >= 32503680000) {
                type = timeGroups[2]
            }

            if (!acc[type]) {
                acc[type] = [];
            }
            acc[type].push(campaign);

            return acc;
        }, {})
    }
    const getGrouped = (campaigns) => {
        return campaigns.campaigns.reduce((acc, campaign) => {
            const type = campaign.type;

            if (filterText !== '' && campaign.id.toLowerCase().indexOf(filterText.toLowerCase()) < 0) return acc;

            if (filterCampaignType !== '') {
                //let campaignType = campaigns.types.filter(item => item.readableType === type)[0]
                let campaignType = campaignTypesByType[type]
                if (campaignType.contentType !== filterCampaignType) return acc;
                //if(filterCampaignType!= '' && campaign.id.toLowerCase().indexOf(filterText.toLowerCase()) < 0) return acc;
            }

            if (filterStatuses !== undefined && filterStatuses.length > 0) {
                if (!filterStatuses.includes(campaign.status)) return acc;
            }

            if (filterType !== undefined && filterType !== 'All' && filterType != null) {
                if (campaign.type != filterType) return acc;
            }

            if (!acc[type]) {
                acc[type] = [];
            }
            acc[type].push(campaign);

            return acc;
        }, {})
    }

    const campaignTypesByType = campaigns==undefined ? {} : campaigns?.types.reduce((acc, item) => {
        acc[item.readableType] = item;
        return acc;
    }, {});
    const campaignsByType = campaigns == undefined ? [] : sortByType?getGrouped(campaigns): getSorted(campaigns);


    const getSortTitle = () => {
        if(sortByType == false) return "view by type"
        return "view recent"
    }
    const getExpandTitle = () => {
        if(showAllGroups == true) return "expand all"
        return "collapse all"
    }
    const getRainbowTitle = () => {
        if(showRainbow == false) return "rainbow"
        return "de-rainbow"
    }

    const prevPropsRef = useRef();
    const prevProps = prevPropsRef.current;
    useEffect(() => {

        if(prevProps==undefined || (prevPropsRef.current != env && error==undefined && !isLoading && !isFetching)) {
            prevPropsRef.current = env
        }
    }, [env, isLoading, isFetching, error]);


    const isNotError = (error==''||error==undefined) || (error?.data?.message?.indexOf('Cursor')>0 && prevPropsRef.current==env)
    const shouldShowContent = (!isLoading&&prevPropsRef.current==env || (prevPropsRef.current!=env&&!isFetching)) && isNotError &&(( !isLoading && !isError) || (prevPropsRef.current==env))
    const shouldShowLoading = ((error!=''&&error!=undefined&&error?.data?.message?.indexOf('Cursor')==-1) || isLoading || (isError && prevPropsRef.current!=env && error?.data?.message?.indexOf('Cursor')==-1) || (isFetching && prevPropsRef.current!=env && prevPropsRef.current!=undefined))

    return (
        <div>
            {shouldShowLoading && <Card  sx={{mt:3.5}} severity="info"><Loading env={env} isLoading={isLoading||isFetching} isError={isError} error={error} refetch={refetch}></Loading></Card>}

            {shouldShowContent&&Object.keys(campaignsByType).length == 0 && <Alert sx={{mt:3.5}} severity="info">No campaigns</Alert>}
            {shouldShowContent&&Object.keys(campaignsByType).length > 0 && <>

                <div className="collapse-all-link" onClick={() => {setShowAllGroups(!showAllGroups);}}>{getExpandTitle()}</div>
               &nbsp;
                <div className="collapse-all-link" onClick={() => {setSortByType(!sortByType);}}>{getSortTitle()}</div>
               &nbsp;
                <div className="collapse-all-link" onClick={() => {
                    setShowRainbow(!showRainbow);
                    window.localStorage.setItem("show_rainbow_" + env, !showRainbow)
                }}>{getRainbowTitle()}</div>

                {Object.keys(campaignsByType).sort().map((type) => (
                    <CampaignItemContext.Provider
                        key={type}
                        value={{
                            env,
                            selectedList,
                            onSelectItem,
                            bundles: campaigns.bundles,
                            createDisabled,
                            type,
                            campaigns: campaigns,
                            types: campaignTypesByType,
                            campaignType: campaignTypesByType[type],
                            items: sortByType&&getDomainAlias() != 'cm' ? campaignsByType[type].sort(sortCampaign) : campaignsByType[type].sort(sortItemsByTime)
                        }}>
                        <CampaignGroup showRainbow={showRainbow} showAllGroups={showAllGroups} rightPanel={rightPanel} />
                    </CampaignItemContext.Provider>
                ))}
            </>}

        </div>
    );
};
